import { FC, useCallback, useContext } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { ErrorMessage } from '@hookform/error-message';

import { GlobalContext } from '../../../components/contextWrapper/contextWrapper';

import style from './walletInput.module.scss';

interface WalletInputProps {
  index: number;
  placeholder?: string;
  register: UseFormRegister<FieldValues>;
  name: string;
  maxFieldsCount: number;
  isLastField: boolean;
  append: (field: FormArrayField) => void;
  remove: (index: number) => void;
  errors?: FieldErrors;
}

export type FormArrayField = { value: string };

const WalletInput: FC<WalletInputProps> = ({
  index,
  placeholder,
  register,
  name,
  maxFieldsCount,
  isLastField,
  append,
  remove,
  errors,
}) => {
  const { web3 } = useContext(GlobalContext);
  const { trigger } = useForm();

  const addField = useCallback(() => {
    append({ value: '' });
    trigger();
  }, [append, trigger]);

  const removeField = useCallback((index: number) => {
    remove(index);
    trigger();
  }, [remove, trigger]);

  return (
    <div className={style.wrapper}>
      <div className={style.inputWrapper}>
        <input
          className={style.input}
          placeholder={placeholder || 'Wallet address'}
          {...register(name, {
            validate: {
              checkAddress: value => value ? web3?.utils.isAddress(value) || 'invalid address' : true,
            },
          })}
        />
        {index < maxFieldsCount - 1 && <img
          className={style.actionButton}
          src={isLastField ? 'add.svg' : 'remove.svg'}
          onClick={isLastField ? addField : () => removeField(index)}
        />}
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({message}) => <span className={style.error}>{message}</span>}
      />
    </div>
  )
};

export default WalletInput;
