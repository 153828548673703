// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".walletInput_wrapper__3hTFK {\n  position: relative;\n  margin-bottom: 20px; }\n  .walletInput_wrapper__3hTFK .walletInput_inputWrapper__k_qMR {\n    display: flex;\n    align-items: center; }\n    .walletInput_wrapper__3hTFK .walletInput_inputWrapper__k_qMR .walletInput_input__XeEVY {\n      width: 320px;\n      padding: 5px; }\n    .walletInput_wrapper__3hTFK .walletInput_inputWrapper__k_qMR .walletInput_actionButton__1TMyy {\n      margin-left: 0.5rem;\n      width: 26px;\n      height: 26px; }\n  .walletInput_wrapper__3hTFK .walletInput_error__1ioXG {\n    position: absolute;\n    color: red;\n    padding: 2px 5px;\n    font-size: 10px;\n    font-weight: normal; }\n", "",{"version":3,"sources":["webpack://src/shared/components/walletInput/walletInput.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB,EAAA;EAFrB;IAKI,aAAa;IACb,mBAAmB,EAAA;IANvB;MASM,YAAY;MACZ,YAAY,EAAA;IAVlB;MAcM,mBAAmB;MACnB,WAAW;MACX,YAAY,EAAA;EAhBlB;IAqBI,kBAAkB;IAClB,UAAU;IACV,gBAAgB;IAChB,eAAe;IACf,mBAAmB,EAAA","sourcesContent":[".wrapper {\n  position: relative;\n  margin-bottom: 20px;\n\n  .inputWrapper {\n    display: flex;\n    align-items: center;\n\n    .input {\n      width: 320px;\n      padding: 5px;\n    }\n\n    .actionButton {\n      margin-left: 0.5rem;\n      width: 26px;\n      height: 26px;\n    }\n  }\n\n  .error {\n    position: absolute;\n    color: red;\n    padding: 2px 5px;\n    font-size: 10px;\n    font-weight: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "walletInput_wrapper__3hTFK",
	"inputWrapper": "walletInput_inputWrapper__k_qMR",
	"input": "walletInput_input__XeEVY",
	"actionButton": "walletInput_actionButton__1TMyy",
	"error": "walletInput_error__1ioXG"
};
export default ___CSS_LOADER_EXPORT___;
