import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import buttonStyle from '../../styles/buttons.module.scss';
import AddToWhitelist from '../addToWhiteList/addToWhitelist';
import Balance from '../balance/balance';
import { GlobalContext } from '../contextWrapper/contextWrapper';
import MintToOtherWallet from '../mintToOtherWallet/mintToOtherWallet';
import Price from '../price/price';
import StopReveal from '../stopReveal/stopReveal';
import WithdrawETH from '../withdrawETH/withdrawETH';

import style from './main.module.scss';
import SetContractOwner from '../setContractOwner/setContractOwner';

const buttonNames = {
  balance: 'balance',
  price: 'price',
  mintToOtherWallet: 'mintToOtherWallet',
  addToWhiteList: 'addToWhiteList',
  removeFromWhiteList: 'removeFromWhiteList',
  stopReveal: 'stopReveal',
  withdrawETH: 'withdrawETH',
  pauseContract: 'pauseContract',
  setContractOwner: 'setContractOwner',
};

const Main = () => {
  const {contractAddress} = useContext(GlobalContext);
  const [activeButton, setActiveButton] = useState<string>();

  const activeButtonSetter = (e: React.MouseEvent<HTMLButtonElement>) => {
    setActiveButton(e.currentTarget.name);
    localStorage.setItem('activeButton', e.currentTarget.name);
  };

  useEffect(() => {
    setActiveButton(localStorage.getItem('activeButton') || buttonNames.balance);
  }, []);

  return (
    <>
      <ToastContainer position="top-center" autoClose={2000} style={{width: '420px'}}/>

      <header className={style.headerContainer}>
        <h1 className={style.mainTitle}>Kriptic Computers Admin Panel</h1>
      </header>
      <h2 className={style.contractAddress}>Contract address: {contractAddress}</h2>

      <nav className={style.navigationContainer}>
        <button
          className={classNames(buttonStyle.mainButton, activeButton === buttonNames.balance && buttonStyle.mainButtonActive)}
          name={buttonNames.balance}
          onClick={activeButtonSetter}
        >
          Balance of wallet
        </button>
        <button
          className={classNames(buttonStyle.mainButton, activeButton === buttonNames.price && buttonStyle.mainButtonActive)}
          name={buttonNames.price}
          onClick={activeButtonSetter}
        >
          Update price
        </button>
        <button
          className={classNames(buttonStyle.mainButton, activeButton === buttonNames.mintToOtherWallet && buttonStyle.mainButtonActive)}
          name={buttonNames.mintToOtherWallet}
          onClick={activeButtonSetter}
        >
          Mint to other wallet
        </button>
        <button
          className={classNames(buttonStyle.mainButton, activeButton === buttonNames.addToWhiteList && buttonStyle.mainButtonActive)}
          name={buttonNames.addToWhiteList}
          onClick={activeButtonSetter}
        >
          Add to whitelist
        </button>
        <button
          className={classNames(buttonStyle.mainButton, activeButton === buttonNames.stopReveal && buttonStyle.mainButtonActive)}
          name={buttonNames.stopReveal}
          onClick={activeButtonSetter}
        >
          Reveal
        </button>
        <button
          className={classNames(buttonStyle.mainButton, activeButton === buttonNames.withdrawETH && buttonStyle.mainButtonActive)}
          name={buttonNames.withdrawETH}
          onClick={activeButtonSetter}
        >
          Withdraw ETH
        </button>
        <button
          className={classNames(buttonStyle.mainButton, activeButton === buttonNames.setContractOwner && buttonStyle.mainButtonActive)}
          name={buttonNames.setContractOwner}
          onClick={activeButtonSetter}
        >
          Set Contract Owner
        </button>
      </nav>
      <main>
        {activeButton === buttonNames.balance && <Balance/>}
        {activeButton === buttonNames.price && <Price/>}
        {activeButton === buttonNames.mintToOtherWallet && <MintToOtherWallet/>}
        {activeButton === buttonNames.addToWhiteList && <AddToWhitelist/>}
        {activeButton === buttonNames.stopReveal && <StopReveal/>}
        {activeButton === buttonNames.withdrawETH && <WithdrawETH/>}
        {activeButton === buttonNames.setContractOwner && <SetContractOwner/>}
      </main>
    </>
  );
};

export default Main;
