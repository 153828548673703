import { useCallback, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { useFieldArray, useForm } from 'react-hook-form';

import WalletInput, { FormArrayField } from '../../shared/components/walletInput/walletInput';
import buttonStyle from '../../styles/buttons.module.scss';
import { GlobalContext } from '../contextWrapper/contextWrapper';

import style from './addToWhitelist.module.scss';

const AddToWhitelist = () => {
  const {contract, wallet} = useContext(GlobalContext);
  const {control, formState: {errors}, register, handleSubmit} = useForm();
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'wallets',
  });

  const addWalletHandler = useCallback(async ({wallets}: { wallets: FormArrayField[] }) => {
    const values = wallets.reduce<string[]>((acc, walletField) => {
      walletField.value && acc.push(walletField.value);
      return acc;
    }, []);
    await contract?.methods.addWhitelistUsers(values)
      .send({ from: wallet });
    remove();
    append({ value: '' });
  }, [append, contract, remove, wallet]);

  useEffect(() => {
    append({ value: '' });
  }, [append]);

  return (
    <form
      className={style.addToWhitelistWrapper}
      onSubmit={handleSubmit(addWalletHandler)}
    >
      {fields.map((field, index) => {
        const isLastField = index === fields.length - 1;
        return (
          <WalletInput
            key={field.id}
            index={index}
            register={register}
            name={`wallets.${index}.value`}
            maxFieldsCount={30}
            isLastField={isLastField}
            append={append}
            remove={remove}
            errors={errors}
          />
        )
      })}
      <div className={style.submitWrapper}>
        <input
          type="submit"
          className={classNames(buttonStyle.mainButton, buttonStyle.mainButtonActive)}
          value="Add wallet"
        />
      </div>
    </form>
  );
};

export default AddToWhitelist;
