import { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';

import buttonStyle from '../../styles/buttons.module.scss';
import { GlobalContext } from '../contextWrapper/contextWrapper';

import style from './price.module.scss';

const Price: FC = () => {
  const { contract, wallet, web3 } = useContext(GlobalContext);
  const [currentPreSalePrice, setCurrentPreSalePrice] = useState<string>('');
  const [newPreSalePrice, setNewPreSalePrice] = useState<string>('');
  const [currentSalePrice, setCurrentSalePrice] = useState<string>('');
  const [newSalePrice, setNewSalePrice] = useState<string>('');
  const [processSubmit, setProcessSubmit] = useState(false);

  const onPreSalePriceChangeHandler = (e: ChangeEvent<HTMLInputElement>) => setNewPreSalePrice(e.target.value);
  const onSalePriceChangeHandler = (e: ChangeEvent<HTMLInputElement>) => setNewSalePrice(e.target.value);

  const submitPreSalePriceHandler = useCallback(async () => {
    if (newPreSalePrice) {
      setProcessSubmit(true);
      const weiPrice = web3?.utils.toWei(newPreSalePrice);
      const res = await contract?.methods.setPreSalePrice(weiPrice)
        .send({ from: wallet })
        .catch(() => {
          setProcessSubmit(false);
        });
      if (res) {
        setCurrentPreSalePrice(newPreSalePrice);
        setNewPreSalePrice('');
        setProcessSubmit(false);
      }
    }
  }, [contract, newPreSalePrice, wallet, web3]);

  const submitSalePriceHandler = useCallback(async () => {
    if (newSalePrice) {
      setProcessSubmit(true);
      const weiPrice = web3?.utils.toWei(newSalePrice);
      const res = await contract?.methods.setSalePrice(weiPrice)
        .send({ from: wallet })
        .catch(() => {
          setProcessSubmit(false);
        });
      if (res) {
        setCurrentSalePrice(newSalePrice);
        setNewSalePrice('');
        setProcessSubmit(false);
      }
    }
  }, [contract, newSalePrice, wallet, web3]);

  const getPreSalePrice = useCallback(async () => {
    const price = await contract?.methods.preSalePrice().call();
    if (price) {
      const etherPrice = web3?.utils.fromWei(price, 'ether');
      etherPrice && setCurrentPreSalePrice(etherPrice);
    }
  }, [contract, web3]);

  const getSalePrice = useCallback(async () => {
    const price = await contract?.methods.salePrice().call();
    if (price) {
      const etherPrice = web3?.utils.fromWei(price, 'ether');
      etherPrice && setCurrentSalePrice(etherPrice);
    }
  }, [contract, web3]);

  useEffect(() => {
    getPreSalePrice();
  }, [getPreSalePrice]);

  useEffect(() => {
    getSalePrice();
  }, [getSalePrice]);

  return (
    <div className={style.pricesWrapper}>
      <div className={style.priceWrapper}>
        <div className={style.currentPriceWrapper}>
          <label htmlFor="currentPreSalePrice">Current pre-sale price</label>
          <input className={style.priceInput} type="text" value={currentPreSalePrice} readOnly={true}/>
        </div>
        <div className={style.newPriceWrapper}>
          <label htmlFor="newPreSalePrice">New pre-sale price</label>
          <input
            className={style.priceInput}
            type="number"
            step="0.01"
            value={newPreSalePrice}
            onChange={onPreSalePriceChangeHandler}
            placeholder="Enter new price"
          />
        </div>
        <button
          className={classNames(buttonStyle.mainButton, buttonStyle.mainButtonActive)}
          onClick={submitPreSalePriceHandler}
          disabled={processSubmit}
        >
          Submit
        </button>
      </div>

      <div className={style.priceWrapper}>
        <div className={style.currentPriceWrapper}>
          <label htmlFor="currentSalePrice">Current sale price</label>
          <input className={style.priceInput} type="text" value={currentSalePrice} readOnly={true}/>
        </div>
        <div className={style.newPriceWrapper}>
          <label htmlFor="newSalePrice">New sale price</label>
          <input
            className={style.priceInput}
            type="number"
            step="0.01"
            value={newSalePrice}
            onChange={onSalePriceChangeHandler}
            placeholder="Enter new price"
          />
        </div>
        <button
          className={classNames(buttonStyle.mainButton, buttonStyle.mainButtonActive)}
          onClick={submitSalePriceHandler}
          disabled={processSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Price;
