// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".withdrawETH_withdrawWrapper__20fAP {\n  display: flex;\n  flex-direction: column;\n  align-items: center; }\n\n.withdrawETH_buttonWrapper__PksuJ {\n  display: flex; }\n", "",{"version":3,"sources":["webpack://src/components/withdrawETH/withdrawETH.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAA;;AAGrB;EACE,aAAa,EAAA","sourcesContent":[".withdrawWrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.buttonWrapper {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"withdrawWrapper": "withdrawETH_withdrawWrapper__20fAP",
	"buttonWrapper": "withdrawETH_buttonWrapper__PksuJ"
};
export default ___CSS_LOADER_EXPORT___;
