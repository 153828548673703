import { createContext, Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';

import { web3Service } from '../../shared/services/web3.service';

export type Context = {
  wallet?: string;
  contract?: Contract;
  contractAddress?: string;
  setContractPaused?: Dispatch<SetStateAction<boolean | undefined>>;
  web3?: Web3;
  contractOwner?: string;
  baseURI?: string;
}

export const GlobalContext = createContext<Context>({
  wallet: '',
  contract: undefined,
  contractAddress: '',
  web3: undefined,
  contractOwner: '',
  baseURI: '',
});

const ContextWrapper: FC = ({children}) => {
  const [wallet, setWallet] = useState<string>();
  const [contract, setContract] = useState<Contract>();
  const [contractAddress, setContractAddress] = useState<string>();
  const [web3, setWeb3] = useState<Web3>();
  const [contractOwner, setContractOwner] = useState<string>();
  const [baseURI, setBaseURI] = useState<string>();

  const getCollectionContract = useCallback(async () => {
    const collectionContract = await web3Service.getCollectionContract()
      .catch(error => {
        toast(error.message);
      });
    if (collectionContract) {
      toast('Connecting your wallet');
    }
    setContractAddress(collectionContract?.contractAddress);
    setContract(collectionContract?.contract);
    setWeb3(collectionContract?.web3);
    setWallet(collectionContract?.wallet);
    setContractOwner(collectionContract?.contractCreator);
    setBaseURI(collectionContract?.baseURI);
  }, []);

  useEffect(() => {
    getCollectionContract();
  }, [getCollectionContract]);

  useEffect(() => {
    (window as any)?.ethereum?.on('accountsChanged', getCollectionContract);
    (window as any)?.ethereum?.on('chainChanged', getCollectionContract);
    return () => {
      (window as any)?.ethereum?.removeListener('accountsChanged', getCollectionContract);
      (window as any)?.ethereum?.removeListener('chainChanged', getCollectionContract);
    }
  }, [getCollectionContract]);

  return (
    <GlobalContext.Provider value={{
      wallet,
      contract,
      contractAddress,
      web3,
      contractOwner,
      baseURI,
    }}>
      {children}
    </GlobalContext.Provider>
  )
};

export default ContextWrapper;
