import React from 'react';

import Main from './components/main/main';
import ContextWrapper from './components/contextWrapper/contextWrapper';

function App() {
  return (
    <div className="App">
      <ContextWrapper>
        <Main/>
      </ContextWrapper>
    </div>
  );
}

export default App;
