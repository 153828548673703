// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttons_mainButton__1dYNi {\n  height: 30px;\n  font-family: 'Montserrat', sans-serif;\n  box-sizing: border-box;\n  border: none;\n  background: transparent;\n  border-radius: 3px;\n  white-space: nowrap; }\n  .buttons_mainButton__1dYNi:not(:last-child) {\n    margin-right: 10px; }\n  .buttons_mainButtonActive__1e8IO {\n    box-sizing: border-box;\n    background: rgba(72, 59, 169, 0.5);\n    border: 1px solid #000000; }\n", "",{"version":3,"sources":["webpack://src/styles/buttons.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,qCAAqC;EACrC,sBAAsB;EACtB,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB,EAAA;EAPrB;IAUI,kBAAkB,EAAA;EAGpB;IACE,sBAAsB;IACtB,kCAAkC;IAClC,yBAAyB,EAAA","sourcesContent":[".mainButton {\n  height: 30px;\n  font-family: 'Montserrat', sans-serif;\n  box-sizing: border-box;\n  border: none;\n  background: transparent;\n  border-radius: 3px;\n  white-space: nowrap;\n\n  &:not(:last-child) {\n    margin-right: 10px;\n  }\n\n  &Active {\n    box-sizing: border-box;\n    background: rgba(72, 59, 169, 0.5);\n    border: 1px solid #000000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainButton": "buttons_mainButton__1dYNi",
	"mainButtonActive": "buttons_mainButtonActive__1e8IO"
};
export default ___CSS_LOADER_EXPORT___;
