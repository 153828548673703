// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".balance_balanceWrapper__1CWIQ {\n  display: flex;\n  flex-direction: column;\n  align-items: center; }\n\n.balance_control__3BO99 {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 20px; }\n\n.balance_textArea__3Am_a {\n  width: 600px;\n  height: 100px;\n  resize: none;\n  padding: 20px;\n  font-size: 20px;\n  -webkit-user-select: none;\n          user-select: none; }\n\n.balance_walletAddressInput__1xPgX {\n  margin-right: 10px;\n  width: 120px;\n  padding: 5px; }\n", "",{"version":3,"sources":["webpack://src/components/balance/balance.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAA;;AAGrB;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB,EAAA;;AAGrB;EACE,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,aAAa;EACb,eAAe;EACf,yBAAiB;UAAjB,iBAAiB,EAAA;;AAGnB;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY,EAAA","sourcesContent":[".balanceWrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.control {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.textArea {\n  width: 600px;\n  height: 100px;\n  resize: none;\n  padding: 20px;\n  font-size: 20px;\n  user-select: none;\n}\n\n.walletAddressInput {\n  margin-right: 10px;\n  width: 120px;\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"balanceWrapper": "balance_balanceWrapper__1CWIQ",
	"control": "balance_control__3BO99",
	"textArea": "balance_textArea__3Am_a",
	"walletAddressInput": "balance_walletAddressInput__1xPgX"
};
export default ___CSS_LOADER_EXPORT___;
