// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mintToOtherWallet_walletInput__1AHrg, .mintToOtherWallet_amountInput__rGIH3 {\n  width: 200px;\n  margin-bottom: 20px;\n  padding: 5px; }\n\n.mintToOtherWallet_mintToOtherWalletWrapper__xCT9w {\n  display: flex;\n  flex-direction: column;\n  align-items: center; }\n", "",{"version":3,"sources":["webpack://src/components/mintToOtherWallet/mintToOtherWallet.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,YAAY,EAAA;;AAGd;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAA","sourcesContent":[".walletInput, .amountInput {\n  width: 200px;\n  margin-bottom: 20px;\n  padding: 5px;\n}\n\n.mintToOtherWalletWrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"walletInput": "mintToOtherWallet_walletInput__1AHrg",
	"amountInput": "mintToOtherWallet_amountInput__rGIH3",
	"mintToOtherWalletWrapper": "mintToOtherWallet_mintToOtherWalletWrapper__xCT9w"
};
export default ___CSS_LOADER_EXPORT___;
