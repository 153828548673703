import { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';

import buttonStyle from '../../styles/buttons.module.scss';
import { GlobalContext } from '../contextWrapper/contextWrapper';

import style from './withdrawETH.module.scss';

const WithdrawETH = () => {
  const {contract, wallet} = useContext(GlobalContext);
  const [processWithdraw, setProcessWithdraw] = useState(false);

  const confirmButtonHandler = useCallback(async () => {
    setProcessWithdraw(true);
    const res = await contract?.methods.withdraw().send({ from: wallet })
      .catch(() => {
        setProcessWithdraw(false);
      });
    if (res) {
      setProcessWithdraw(false);
    }
  }, [contract, wallet]);

  return (
    <div className={style.withdrawWrapper}>
      <h2>Are you sure you want to withdraw ether?</h2>

      <div className={style.buttonWrapper}>
        <button
          className={classNames(buttonStyle.mainButton, buttonStyle.mainButtonActive)}
          onClick={confirmButtonHandler}
          disabled={processWithdraw}
        >
          Yes
        </button>
      </div>
    </div>
  );
};

export default WithdrawETH;
