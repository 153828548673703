import { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import bluebird from 'bluebird';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import buttonStyle from '../../styles/buttons.module.scss';
import { GlobalContext } from '../contextWrapper/contextWrapper';

import style from './stopReveal.module.scss';

const StopReveal = () => {
  const { contract, wallet, baseURI } = useContext(GlobalContext);
  const [processStopReveal, setStopReveal] = useState(false);
  const [isPostReveal, setIsPostReveal] = useState(false);
  const [refreshedItem, setRefreshedItem] = useState<number>();
  const [mintedNFTsCount, setMintedNFTsCount] = useState<number>();

  useEffect(() => {
    const isPostReveal = baseURI === process.env.REACT_APP_POST_REVEAL_BASE_URL;
    setIsPostReveal(isPostReveal);
  }, [baseURI]);

  const refreshOpenSeaNFTsMetadata = async () => {
    const mintedNFTsCount = await contract?.methods.totalSupply().call().then((res: string) => Number(res));
    setMintedNFTsCount(mintedNFTsCount);
    if (mintedNFTsCount > 0) {
      await bluebird.each(
        new Array(mintedNFTsCount).fill(null),
        async (_, i) => {
          await axios.get(
            `${process.env.REACT_APP_OPENSEA_API_URL}/${contract?.options.address}/${i + 1}`,
            {
              params: {
                force_update: true,
              },
            },
          )
          .then(() => setRefreshedItem(i + 1))
          .catch((err) => toast(err.message));
          await bluebird.delay(1000);
        },
      );
    }
  };

  const confirmButtonHandler = useCallback(async () => {
    setStopReveal(true);
    const res = await contract?.methods.setBaseURI(process.env.REACT_APP_POST_REVEAL_BASE_URL)
      .send({ from: wallet })
      .catch(() => {
        setStopReveal(false);
      });
    if (res) {
      await refreshOpenSeaNFTsMetadata();
    }
  }, [contract, wallet]);

  return (
    <div className={style.stopRevealWrapper}>
      <h2>Are you sure you want to reveal and allow real NFTs to be displayed?</h2>

      <div className={style.buttonWrapper}>
        <button
          className={classNames(buttonStyle.mainButton, buttonStyle.mainButtonActive)}
          onClick={confirmButtonHandler}
          disabled={isPostReveal || processStopReveal}
        >
          Yes
        </button>
        <button
          className={classNames(buttonStyle.mainButton, buttonStyle.mainButtonActive)}
          onClick={refreshOpenSeaNFTsMetadata}
        >
          Refresh metadata
        </button>
      </div>
      {refreshedItem && <div>Refreshed {refreshedItem} from {mintedNFTsCount}</div>}
    </div>
  );
};

export default StopReveal;
