import { ChangeEvent, useCallback, useContext, useState } from 'react';
import classNames from 'classnames';

import buttonStyle from '../../styles/buttons.module.scss';
import { GlobalContext } from '../contextWrapper/contextWrapper';

import style from './mintToOtherWallet.module.scss';

const MintToOtherWallet = () => {
  const {contract, wallet} = useContext(GlobalContext);
  const [walletAddress, setWalletAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [processMint, setProcessMint] = useState(false);

  const onChangeWalletAddressHandler = (e: ChangeEvent<HTMLInputElement>) => setWalletAddress(e.target.value);
  const onChangeAmountHandler = (e: ChangeEvent<HTMLInputElement>) => setAmount(e.target.value);
  const mintHandler = useCallback(async () => {
    setProcessMint(true);
    const res = await contract?.methods.mint(walletAddress, amount)
      .send({ from: wallet })
      .catch(() => {
        setProcessMint(false);
      });
    if (res) {
      setAmount('');
      setWalletAddress('');
      setProcessMint(false);
    }
  }, [amount, contract, wallet, walletAddress]);

  return (
    <div className={style.mintToOtherWalletWrapper}>
      <input
        className={style.walletInput}
        type="text"
        value={walletAddress}
        onChange={onChangeWalletAddressHandler}
        placeholder="Wallet address"
      />
      <input
        className={style.amountInput}
        type="number"
        placeholder="Amount"
        value={amount}
        onChange={onChangeAmountHandler}
      />
      <button
        className={classNames(buttonStyle.mainButton, buttonStyle.mainButtonActive)}
        onClick={mintHandler}
        disabled={processMint}
      >
        Mint
      </button>
    </div>
  );
};

export default MintToOtherWallet;
