// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".setContractOwner_stopRevealWrapper__39CPK {\n  display: flex;\n  flex-direction: column;\n  align-items: center; }\n\n.setContractOwner_buttonWrapper__39wK_ {\n  display: flex; }\n\n.setContractOwner_control__2Cl3L {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 20px; }\n\n.setContractOwner_walletAddressInput__2ylKi {\n  margin-right: 10px;\n  width: 320px;\n  padding: 5px; }\n", "",{"version":3,"sources":["webpack://src/components/setContractOwner/setContractOwner.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAA;;AAGrB;EACE,aAAa,EAAA;;AAGf;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB,EAAA;;AAGrB;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY,EAAA","sourcesContent":[".stopRevealWrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.buttonWrapper {\n  display: flex;\n}\n\n.control {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.walletAddressInput {\n  margin-right: 10px;\n  width: 320px;\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stopRevealWrapper": "setContractOwner_stopRevealWrapper__39CPK",
	"buttonWrapper": "setContractOwner_buttonWrapper__39wK_",
	"control": "setContractOwner_control__2Cl3L",
	"walletAddressInput": "setContractOwner_walletAddressInput__2ylKi"
};
export default ___CSS_LOADER_EXPORT___;
