// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".price_priceInput__3R5dY {\n  width: 120px;\n  padding: 5px; }\n\nlabel {\n  margin-bottom: 5px; }\n\n.price_pricesWrapper__1h6A5 {\n  display: flex;\n  justify-content: center;\n  align-items: flex-start; }\n\n.price_priceWrapper__JptN3 {\n  display: flex;\n  flex-direction: column;\n  align-items: center; }\n\n.price_currentPriceWrapper__2_wmE {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 10px; }\n\n.price_newPriceWrapper__tjU8K {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 10px; }\n", "",{"version":3,"sources":["webpack://src/components/price/price.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY,EAAA;;AAGd;EACE,kBAAkB,EAAA;;AAGpB;EACE,aAAa;EACb,uBAAuB;EACvB,uBAAuB,EAAA;;AAGzB;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAA;;AAGrB;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY,EAAA;;AAGd;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY,EAAA","sourcesContent":[".priceInput {\n  width: 120px;\n  padding: 5px;\n}\n\nlabel {\n  margin-bottom: 5px;\n}\n\n.pricesWrapper {\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n}\n\n.priceWrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.currentPriceWrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 10px;\n}\n\n.newPriceWrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"priceInput": "price_priceInput__3R5dY",
	"pricesWrapper": "price_pricesWrapper__1h6A5",
	"priceWrapper": "price_priceWrapper__JptN3",
	"currentPriceWrapper": "price_currentPriceWrapper__2_wmE",
	"newPriceWrapper": "price_newPriceWrapper__tjU8K"
};
export default ___CSS_LOADER_EXPORT___;
