// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".addToWhitelist_addToWhitelistWrapper__1lJPc {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start; }\n  .addToWhitelist_addToWhitelistWrapper__1lJPc .addToWhitelist_submitWrapper__31uM2 {\n    align-self: center; }\n", "",{"version":3,"sources":["webpack://src/components/addToWhiteList/addToWhitelist.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB,EAAA;EAHzB;IAMI,kBAAkB,EAAA","sourcesContent":[".addToWhitelistWrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n\n  .submitWrapper {\n    align-self: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addToWhitelistWrapper": "addToWhitelist_addToWhitelistWrapper__1lJPc",
	"submitWrapper": "addToWhitelist_submitWrapper__31uM2"
};
export default ___CSS_LOADER_EXPORT___;
