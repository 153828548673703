// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main_headerContainer__2Pmdh {\n  margin: 60px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center; }\n  .main_headerContainer__2Pmdh .main_mainTitle__2VNg2 {\n    font-weight: bold;\n    font-size: 48px;\n    line-height: 98px;\n    color: #483BA9; }\n\n.main_contractAddress__M2r1e {\n  margin: 40px 60px; }\n\n.main_navigationContainer__2WpM8 {\n  margin: 0 60px 100px;\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap; }\n\n.main_hideLink__1TPkM {\n  display: none; }\n\nmain {\n  display: flex;\n  justify-content: center; }\n", "",{"version":3,"sources":["webpack://src/components/main/main.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB,EAAA;EAJrB;IAOI,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,cAAc,EAAA;;AAIlB;EACE,iBAAiB,EAAA;;AAGnB;EACE,oBAAoB;EACpB,aAAa;EACb,8BAA8B;EAC9B,eAAe,EAAA;;AAGjB;EACE,aAAa,EAAA;;AAGf;EACE,aAAa;EACb,uBAAuB,EAAA","sourcesContent":[".headerContainer {\n  margin: 60px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  .mainTitle {\n    font-weight: bold;\n    font-size: 48px;\n    line-height: 98px;\n    color: #483BA9;\n  }\n}\n\n.contractAddress {\n  margin: 40px 60px;\n}\n\n.navigationContainer {\n  margin: 0 60px 100px;\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n}\n\n.hideLink {\n  display: none;\n}\n\nmain {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": "main_headerContainer__2Pmdh",
	"mainTitle": "main_mainTitle__2VNg2",
	"contractAddress": "main_contractAddress__M2r1e",
	"navigationContainer": "main_navigationContainer__2WpM8",
	"hideLink": "main_hideLink__1TPkM"
};
export default ___CSS_LOADER_EXPORT___;
